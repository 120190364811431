import { PrismaClient } from "@prisma/client";
// biome-ignore lint/performance/noBarrelFile: <explanation>
export * from "@prisma/client";

let prismaClient: PrismaClient;

declare global {
  var __prismaClient: PrismaClient | undefined;
}

if (process.env.NODE_ENV === "production") {
  prismaClient = getPrismaInstance(1, ["info", "warn"]) as PrismaClient;
} else {
  if (!global.__prismaClient) {
    global.__prismaClient = getPrismaInstance(1, ["info", "warn"]) as PrismaClient;
  }
  prismaClient = global.__prismaClient;
}

export function getPrismaInstance(connectionLimit: number, logging: any) {
  return new PrismaClient({
    log: logging,
    datasources: {
      db: {
        url: process.env.POSTGRES_URL_NON_POOLING + "?connection_limit=" + connectionLimit + "&pool_timeout=60",
      },
    },
  });
}

export { prismaClient };

export { getEquipmentIdPropertyNameFromType } from "./utils";
